import React from 'react';
import { graphql } from 'gatsby';
import { Container } from 'gatsby-theme-sanity-evelan/src/components/container/container';
import { BreakOutContainer } from 'gatsby-theme-sanity-evelan/src/components/container/break-out-container';
import { LayoutContentData } from 'gatsby-theme-sanity-evelan/src/components/layout/layout-content-data';
import { AppContext } from 'gatsby-theme-sanity-evelan/src/contexts/AppContext';
import { BlockContent } from '../components/block-content/block-content';
import { SEO } from 'gatsby-theme-sanity-evelan/src/components/seo';
import VideoWithPreview from 'gatsby-theme-sanity-evelan/src/components/asset/video-with-preview/video-with-preview';
import { ShareToolbar } from '../components/blog/share-toolbar/share-toolbar';
import TrailAnimation from 'gatsby-theme-sanity-evelan/src/components/animate/TrailAnimation';
import PostCard from '../components/blog/post-card/post-card';
import { helpers } from 'gatsby-theme-sanity-evelan';
import Authors from 'gatsby-theme-sanity-evelan/src/components/blog/author/authors';
import { extractExcerptFromArticle } from 'gatsby-theme-sanity-evelan/src/components/blog/blog-utils';

import styles from './blog-post.module.scss';

const BlogPost = ({ data, location, pageContext: { posts = [], index } = {} }) => {
  const post = data?.post;
  if (!post) {
    return null;
  }
  const title = post?.title;
  const seoTitle = post?.seoTitle;
  const description = post?.description;
  const keywords = post?.keywords;
  const publishedAt = post?.publishedAt;
  const mainVideo = post?.mainVideo;
  const mainImage = post?.mainImage;
  const hex = post?.category?.color?.hex ?? '';
  const authors = post?.authors ?? [];
  const body = post?.body ?? [];

  const { formatDate, getClosestPosts, shouldUseDarkOrLight, isNotBlankString } = helpers;
  const navigation = getClosestPosts(posts, index, 3);

  const contrastTextColor = shouldUseDarkOrLight(hex, '#1d1d1d', '#ffffff');
  const seoDescription = isNotBlankString(description) ? description : extractExcerptFromArticle(body);
  return (
    <AppContext>
      <LayoutContentData>
        <SEO
          title={isNotBlankString(seoTitle) ? seoTitle : title}
          description={seoDescription}
          keywords={keywords}
          image={mainImage}
          type="article"
          location={location}
        />
        <div>
          <div style={{ background: hex }}>
            <Container variant="thin" className={styles.header}>
              <p className={styles.blogDate}>{formatDate(publishedAt)}</p>
              <h1 className={styles.title}>{title}</h1>
              <div className={styles.shareToolbarContainer}>
                <ShareToolbar
                  text="Teilen"
                  url={location.href}
                  style={{
                    borderColor: contrastTextColor,
                    color: contrastTextColor,
                    fill: contrastTextColor,
                  }}
                />
              </div>
            </Container>
          </div>
          <Container variant="thin">
            {mainImage && (
              <div className={styles.videoWrapper}>
                <BreakOutContainer>
                  <VideoWithPreview
                    previewAsset={mainImage}
                    video={mainVideo}
                    autoPlay={location.state && location.state.hasOwnProperty('autoPlay') && location.state.autoPlay}
                  />
                </BreakOutContainer>
              </div>
            )}
          </Container>
          {body && (
            <Container variant="extra-thin">
              <BlockContent blocks={body} />
              {authors.length > 0 ? (
                <>
                  <div className={styles.hrHolder} />
                  <Authors authors={authors} />
                </>
              ) : null}
            </Container>
          )}
        </div>
        <div>
          <Container variant="thin" className={styles.blogLatest}>
            <h2 className={styles.blogTitle}>Weitere Artikel</h2>
            <TrailAnimation component={<div />} variant="SlideUpSlow" visibleAt={25}>
              <div className={styles.blogTeaserWrapper}>
                {navigation.map((post, index) => (
                  <PostCard
                    key={index}
                    mainImage={post.mainImage}
                    mainVideo={post.mainVideo}
                    publishedDate={formatDate(post.publishedAt)}
                    category={post.category.name}
                    title={post.title}
                    body={post.body}
                    color={post.category.color.hex}
                    slug={`${post.slug.current}`}
                  />
                ))}
              </div>
            </TrailAnimation>
          </Container>
        </div>
      </LayoutContentData>
    </AppContext>
  );
};

export default BlogPost;

export const query = graphql`
  query BlogPostSlug($id: String!) {
    post: sanityPost(_id: { regex: $id }) {
      body: _rawBody(resolveReferences: { maxDepth: 10 })
      title
      id
      category {
        _id
        color {
          hex
        }
      }
      slug {
        current
      }
      authors {
        activity
        author {
          name
          role
          image: _rawImage(resolveReferences: { maxDepth: 3 })
        }
      }
      publishedAt
      mainImage: _rawMainImage(resolveReferences: { maxDepth: 10 })
      mainVideo {
        url
      }
    }
  }
`;
